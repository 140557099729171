const menuConfig = [
  {
    title: 'Home',
    path: 'homeSection'
  },
  {
    title: 'Product',
    path: 'productSection'
  },
  {
    title: 'Feature',
    path: 'featureSection'
  },
  {
    title: 'Pricing',
    path: 'pricingSection'
  },
  {
    title: 'Login',
    path: 'https://www.sparkletimekeeping.com/login'
  },
];

export default menuConfig;