import React, { useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { Card, Grid, Container, Stack, TextField, Typography, Box } from '@mui/material';
import { motion } from 'framer-motion';
import { varFadeInLeft, varFadeInRight } from '../animate';

const RootStyle = styled('div')(() => ({
  position: 'relative',
  marginBottom: 64,
  paddingTop: 42,
  paddingBottom: 42,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export default function HomeContactForm() {
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      contact: '',
      message: '',
    },
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        const webhookURL = 'https://discord.com/api/webhooks/1100783211891654716/3ZXsQiXkUQsK5LeI9bh5O35hZ1Qqh_c4hSIyQW3SF43CtGQbqngqP2Wq_u3ImRjaUm7x';

        const payload = {
          content: `Name: ${values.name}\nEmail: ${values.email}\nContact: ${values.contact}\nMessage: ${values.message}`,
        };

        const response = await fetch(webhookURL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });

        if (response.ok) {
          alert('Message sent successfully!');
          resetForm();
        } else {
          alert('Error sending message');
        }
      } catch (error) {
        console.error('Error sending message:', error);
        alert('Error sending message');
      } finally {
        setSubmitting(false);
      }
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <RootStyle>
      <Container maxWidth="lg">
        <Grid container spacing={5} justifyContent="center" alignItems="center">
          <Grid item xs={12} md={6}>
            <motion.div variants={varFadeInLeft}>
              <FormikProvider value={formik}>
                <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                  <Card sx={{ px: 3, py: 5, backgroundColor: '#0176BC' }}>
                    <Typography variant='h3' color='white' sx={{mb:'3vh'}}> Book a Demo </Typography>
                    <Stack spacing={3}>
                      <TextField
                        fullWidth
                        label="Name"
                        {...getFieldProps('name')}
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                        sx={{ backgroundColor: 'white', borderRadius: '12px' }}
                      />
                      <TextField
                        fullWidth
                        label="Email address"
                        {...getFieldProps('email')}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                        sx={{ backgroundColor: 'white', borderRadius: '12px' }}
                      />
                      <TextField
                        fullWidth
                        label="Contact No."
                        {...getFieldProps('contact')}
                        error={Boolean(touched.contact && errors.contact)}
                        helperText={touched.contact && errors.contact}
                        sx={{ backgroundColor: 'white', borderRadius: '12px' }}
                      />
                      <TextField
                        fullWidth
                        multiline
                        minRows={3}
                        maxRows={5}
                        label="Message"
                        {...getFieldProps('message')}
                        error={Boolean(touched.message && errors.message)}
                        helperText={touched.message && errors.message}
                        sx={{ backgroundColor: 'white', borderRadius: '12px' }}
                      />
                    </Stack>
                    <Stack direction="row" justifyContent="flex-end" sx={{ mt: 3 }}>
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        size="large"
                        sx={{
                          color: 'blue.light',
                          backgroundColor: 'white',
                          transition: 'all 0.4s ease',
                          borderRadius: '20px',
                          '&:hover': {
                            color: 'white',
                            backgroundColor: 'blue.light',
                            transition: 'all 0.4s ease',
                          },
                        }}
                        loading={isSubmitting}
                      >
                        Submit
                      </LoadingButton>
                    </Stack>
                  </Card>
                </Form>
              </FormikProvider>
            </motion.div>
          </Grid>
          <Grid item xs={12} md={6} display="flex" flexDirection="column" alignItems="center">
            <motion.div variants={varFadeInRight} style={{ width: '100%' }}>
              <Typography
                variant="h5"
                sx={{
                  color: 'common.black',
                  marginBottom: 2,
                  textAlign: 'center',
                }}
              >
                Clock in, Sparkle out!
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  color: 'common.black',
                  fontWeight: 400,
                  textAlign: 'center',
                }}
              >
                Join us and experience the ease, reliability, and convenience of Sparkle Timekeeping!
                <br />
                <br />
                Try Sparkle Timekeeping today and experience the difference!
              </Typography>
              <Box
                component="img"
                src="/static/formBackground.png"
                sx={{
                  width: { xs: '100%', sm: '75%', md: '100%' },
                  height: 'auto',
                  display: 'block',
                  marginTop: 3,
                }}
              />
            </motion.div>
          </Grid>
        </Grid>
      </Container>
    </RootStyle>
  );
}
