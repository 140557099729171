import { Link } from 'react-scroll';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { Icon } from '@iconify/react';
import { useState } from 'react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import { alpha, styled } from '@mui/material/styles';
import {
  Box,
  List,
  Drawer,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import Scrollbar from '../components/Scrollbar';
import { MIconButton } from '../components/@material-extend';
import menuConfig from './MenuConfig';

const ICON_SIZE = 22;
const ITEM_SIZE = 48;
const PADDING = 2.5;

const ListItemStyle = styled(Link)(({ theme }) => ({
  ...theme.typography.body2,
  height: ITEM_SIZE,
  textTransform: 'capitalize',
  textDecoration: 'none',
  paddingLeft: theme.spacing(PADDING),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'  // Center the content
}));

MenuMobileItem.propTypes = {
  item: PropTypes.object,
  isOpen: PropTypes.bool,
  isActive: PropTypes.bool,
  onOpen: PropTypes.func
};

function MenuMobileItem({ item, isOpen, isActive, onOpen }) {
  const { title, path, icon } = item;
  const isExternal = path.startsWith('https');

  return (
    <>
      {isExternal ? (
        <ListItemStyle
          as="a"
          key={title}
          href={path}
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            ...(isActive && {
              color: 'blue.main',
              fontWeight: 'fontWeightMedium',
              bgcolor: (theme) => alpha(theme.palette.blue.main, theme.palette.action.selectedOpacity)
            })
          }}
        >
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText disableTypography primary={title} />
        </ListItemStyle>
      ) : (
        <ListItemStyle
          key={title}
          to={path}
          smooth={true}
          duration={500}
          sx={{
            ...(isActive && {
              color: 'blue.main',
              fontWeight: 'fontWeightMedium',
              bgcolor: (theme) => alpha(theme.palette.blue.main, theme.palette.action.selectedOpacity)
            })
          }}
        >
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText disableTypography primary={title} />
        </ListItemStyle>
      )}
    </>
  );
}

MenuMobile.propTypes = {
  isOffset: PropTypes.bool,
  isHome: PropTypes.bool
};

export default function MenuMobile({ isOffset, isHome }) {
  const [open, setOpen] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerOpen = () => {
    setMobileOpen(true);
  };

  const handleDrawerClose = () => {
    setMobileOpen(false);
  };

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      <MIconButton
        onClick={handleDrawerOpen}
        sx={{
          ml: 1,
          ...(isHome && { color: 'blue.main' }),
          ...(isOffset && { color: 'text.primary' })
        }}
      >
        <Icon icon={menu2Fill} />
      </MIconButton>

      <Drawer
        open={mobileOpen}
        onClose={handleDrawerClose}
        ModalProps={{ keepMounted: true }}
        PaperProps={{ sx: { pb: 5, width: 260 } }}
      >
        <Scrollbar>
          <Link to="/" sx={{ display: 'inline-flex' }}>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                py: 3
              }}
            >
              <motion.img
                src="/static/logo.png"
                style={{
                  maxWidth: '100%',
                  height: 'auto'
                }}
                alt="Logo"
              />
            </Box>
          </Link>

          <List 
            disablePadding 
            sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'start' // Center the list items
            }}
          >
            {menuConfig.map((link) => (
              <MenuMobileItem
                key={link.title}
                item={link}
                isOpen={open}
                onOpen={handleOpen}
              />
            ))}
          </List>
        </Scrollbar>
      </Drawer>
    </>
  );
}
