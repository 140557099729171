import YouTube from 'react-youtube';
// material
import { styled } from '@mui/material/styles';
import { Container, Typography, Grid, Button, Link, Box } from '@mui/material';
import { motion } from 'framer-motion';
import { varFadeInLeft, varFadeInRight } from '../animate';

const RootStyle = styled('div')(({theme}) => ({
  position: 'relative',
  marginBottom: '30vh',
  paddingTop: 42,
  paddingBottom: 42,
  height: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {
   marginBottom:'10vh'
  },
  
}));

const ResponsiveYouTubeWrapper = styled('div')({
  position: 'relative',
  paddingBottom: '56.25%', // 16:9 aspect ratio
  height: 0,
  overflow: 'hidden',
  maxWidth: '100%',
  background: '#000',
  '& iframe': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
});

const YouTubePlayer = ({ videoId }) => {
  const opts = {
    playerVars: {
      autoplay: 1,
    },
  };

  return (
    <ResponsiveYouTubeWrapper>
      <YouTube videoId={videoId} opts={opts} />
    </ResponsiveYouTubeWrapper>
  );
};

export default function HomeAbout() {
  return (
    <RootStyle>
      <Box sx={{ width: '90%', display: 'flex', justifyContent: 'center' }}>
        <Grid container spacing={5} sx={{ width: '100%' }}>
          <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <motion.div variants={varFadeInLeft} style={{ marginTop: 0, width: '100%' }}>
              <YouTubePlayer videoId={'AtGwDkSdrhs'} />
            </motion.div>
          </Grid>
          <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
            <motion.div variants={varFadeInRight} style={{ marginTop: 0, width: '100%' }}>
              <Typography
                variant="h3"
                sx={{
                  color: '#0176BC',
                  margin: 'auto',
                  mb: 2, // reduce margin bottom
                  mt: { xs: 5, sm: 0, md: 0 },
                  textAlign: { xs: 'center', md: 'left' }
                }}
              >
                About Sparkle Time-Keeping System
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  color: 'common.black',
                  mb: 2, // reduce margin bottom
                  fontWeight: 400,
                  textAlign: { xs: 'center', md: 'left' }
                }}
                component="p"
              >
                Sparkle Time-Keeping System was founded with a mission to revolutionize time management in various industries. Our team is dedicated to providing innovative solutions that empower organizations to optimize productivity and accountability.
              </Typography>
              <Link
                color="common.white"
                href="/inquiry"
                sx={{
                  display: 'block',
                  width: '100%',
                  display: 'flex',
    alignItems: 'center',
    justifyContent: {xs:'center',sm:'center', md:'flex-start'}, 
                  '&:hover': {
                    textDecoration: 'none',
                  },
                }}
              >
                <Button
                  component="p"
                  variant="outlined"
                  size="medium"
                  sx={{
                    fontSize: {md:16, sm:12}, 
                    my: 2, 
                    py: '15px', 
                    px: '45px',
                    transition: 'all 0.4s ease',
                    backgroundColor: 'white',
                    color: '#000',
                    borderRadius: '40px',
                    borderColor: '#000',
                    borderWidth: '2px', 
                    borderStyle: 'solid',
                    '&:hover': {
                      transition: 'all 0.4s ease',
                      transform: 'scale(1.05)',
                      backgroundColor: 'blue.main',
                      color: 'common.white',
                    },
                  }}
                >
                  Contact
                </Button>
              </Link>
            </motion.div>
          </Grid>
        </Grid>
      </Box>
    </RootStyle>
  );
}
