import { Link, Element } from 'react-scroll';
import { motion } from 'framer-motion';
// material
import { styled } from '@mui/material/styles';
import { Button, Container, Typography, Stack, Box, Grid } from '@mui/material';

//
import { varFadeIn, varFadeInUp, varWrapEnter, varFadeInDown } from '../animate';

// ----------------------------------------------------------------------

const RootStyle = styled(motion.div)(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.grey[400],
  overflow: 'hidden',
  [theme.breakpoints.up('md')]: {
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh',
    display: 'flex',
    alignItems: 'center'
  }
}));

const ContentStyle = styled((props) => <Stack spacing={5} {...props} />)(({ theme }) => ({
  zIndex: 10,
  maxWidth: '100%',
  margin: 'auto',
  textAlign: 'center',
  position: 'relative',
  paddingTop: theme.spacing(10),
  paddingBottom: theme.spacing(5),
  paddingLeft: theme.spacing(2), // Optional, for balance
  paddingRight: theme.spacing(2), // Adjust this value as needed
  display: 'flex',
  alignItems: 'left',
  [theme.breakpoints.up('md')]: {
    paddingLeft: theme.spacing(2), // Optional, for balance
    paddingRight: theme.spacing(5), // Increase right padding for larger screens
    marginLeft: '10%', // Move content slightly to the right
    marginTop: '7%',
  },
  [theme.breakpoints.down('md')]: {
    marginLeft: '2%', // Apply 2% margin-left for mobile screens
  },
}));



const HeroImgStyle = styled(motion.img)(({ theme }) => ({
  top: 0,
  right: 0,
  bottom: 0,
  zIndex: 8,
  width: '100%',
  height: '100vh',
  margin: 'auto',
  position: 'absolute',
  [theme.breakpoints.up('md')]: {
    objectFit: 'cover',
    objectPosition: 'center',
  },
  [theme.breakpoints.down('md')]: {
    objectFit: 'contain',
    objectPosition: 'center',
  },
}));

const ImgStyle = styled('div')(() => ({
  width: '50%', // Adjust the width as needed
  height: 'auto', // Ensure the aspect ratio is maintained
}));

// ----------------------------------------------------------------------

export default function LandingHero() {
  return (
    <>
      <RootStyle initial="initial" animate="animate" variants={varWrapEnter}>
        <HeroImgStyle
          alt="get-started-get-discovered"
          src="/static/banner.png"
          variants={varFadeInUp}
          style={{
            height: '100vh',
            objectPosition: 'center'
          }}
        />

        <ContentStyle>
          <motion.div variants={varFadeInUp} style={{ marginTop: 0 }}>
            <Box
              sx={{
                textAlign: { xs: 'left', sm: 'left', md: 'center' },
                marginLeft: { xs: '1vh' },
                marginRight: { xs: '1vh' }, // Optional
              }}
            >
              <Typography
                sx={{
                  color: 'common.white',
                  width: { xs: '100%', sm: '100%', md: '100%' },
                  mt: { xs: 1, sm: 0, md: 0 },
                  fontWeight: { xs: 600, md: 500 },
                  fontSize: { xs: '18px', md: '70px' }
                }}
              >
                Modern Time Tracker
              </Typography>
              <Typography
                sx={{
                  color: 'common.white',
                  width: { xs: '100%', sm: '100%', md: '100%' },
                  fontWeight: { xs: 500, md: 400 },
                  fontSize: { xs: '18px', md: '70px' }
                }}
              >
                Suitable for every Business
              </Typography>
            </Box>

            <Box sx={{ width: { xs: '100%', md: '100%' }, mt:{xs:5, md:13} }}>
              <Grid container spacing={1} sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
                <Grid item xs="auto">
                  <Link
                    sx={{
                      '&:hover': {
                        textDecoration: 'none'
                      }
                    }}
                    rel="noreferrer"
                    to="productSection"
                    smooth={true}
                    duration={500}
                  >
                    <Button
                      size="large"
                      variant="contained"
                      sx={{ boxShadow: 'none', borderRadius: '35px', backgroundColor: 'white' }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          paddingLeft: { xs: 0, md: 5 },
                          paddingRight: { xs: 0, md: 5 },
                          paddingTop: { xs: 0, md: 2 },
                          paddingBottom: { xs: 0, md: 2 },
                          color: '#0000FF'
                        }}
                      >
                        Learn More
                      </Typography>
                    </Button>
                  </Link>
                </Grid>
                <Grid item xs="auto">
                  <Link
                    sx={{
                      '&:hover': {
                        textDecoration: 'none'
                      }
                    }}
                    rel="noreferrer"
                    to="formSection"
                    smooth={true}
                    duration={500}
                  >
                    <Button
                      size="large"
                      variant="contained"
                      sx={{ boxShadow: 'none', borderRadius: '35px', backgroundColor: '#195591' }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          paddingLeft: { xs: 0, md: 5 },
                          paddingRight: { xs: 0, md: 5 },
                          paddingTop: { xs: 0, md: 2 },
                          paddingBottom: { xs: 0, md: 2 }
                        }}
                      >
                        Request Demo
                      </Typography>
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </motion.div>
        </ContentStyle>
      </RootStyle>
    </>
  );
}
