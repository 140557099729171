// material
import { Container, Typography, Grid, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
//
import { motion } from 'framer-motion';
import { varFadeInLeft, varFadeInRight, varFadeInDown } from '../animate';

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  marginBottom: 64,
  marginTop: '5vh',
  paddingBottom: 42,
  display: 'flex',
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {
    marginTop: '1vh',
  },
}));

export default function HomeAboutContent() {
  return (
    <RootStyle>
      <Box sx={{ width: '95%', display: 'flex', justifyContent: 'center' }}>
        <Grid container spacing={5} sx={{ width: '100%' }}>
          <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
            <motion.div variants={varFadeInRight} style={{ marginTop: 0, width: '100%', textAlign: 'center' }}>
              <Typography
                variant="h2"
                sx={{
                  color: '#0176BC',
                  margin: 'auto',
                  mb: 5,
                  mt: { xs: 5, sm: 0, md: 0 },
                  fontSize: { xs: '1.5rem', sm: '2.5rem', md: '3rem' },
                }}
              >
                Tailored Solutions for Various Industries
              </Typography>
              <Typography variant="h5" sx={{ color: 'common.black', mb: 5, fontWeight: 400, textAlign: 'left', fontSize: { xs: '1rem', sm: '1.25rem', md: '1.5rem' } }} component="p">
                Sparkle Time-Keeping System caters to a diverse range of industries including restaurant services, healthcare, construction, retail, and more. Our customizable solutions are tailored to meet the unique needs and challenges of each sector, ensuring seamless integration and maximum efficiency.
              </Typography>
            </motion.div>
          </Grid>
          <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
            <motion.div variants={varFadeInLeft}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: 2,
                  boxShadow: 0,
                  maxWidth: '100%',
                }}
              >
                <Box
                  component="video"
                  sx={{
                    width: '100%',
                    borderRadius: '10px',
                  }}
                  controls
                >
                  <source src="/static/commercial.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </Box>
              </Box>
            </motion.div>
          </Grid>
        </Grid>
      </Box>
    </RootStyle>
  );
}
