import { Icon } from '@iconify/react';
import facebookFill from '@iconify/icons-eva/facebook-fill';
import { Link as ScrollLink } from 'react-scroll';
// material
import { styled } from '@mui/material/styles';
import { Grid, Link, Divider, Container, Typography, Stack, Box } from '@mui/material';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default,
  paddingTop: theme.spacing(5),
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  return (
    <RootStyle>
      <Divider />
      <Grid container spacing={5} justifyContent="center" alignItems="center">
        <Grid item xs={12} md={3} sx={{ textAlign: 'center' }}>
          <ScrollLink to="move_top" spy smooth>
            <Box component="img" src="/static/logo.png" sx={{ width: 200, objectFit: 'contain' }} />
          </ScrollLink>
        </Grid>
        <Grid item xs={12} md={3} sx={{ textAlign: { xs: 'center', md: 'left' }, paddingLeft: { xs: 3, md: '15px' } }}>
          <Typography variant="body1" sx={{ mb: 1 }}>
            Home
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            Pricing
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            About Us
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            Contact us
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            Privacy Policy
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            Terms of Services
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            Security
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} sx={{ textAlign: { xs: 'center', md: 'left' }, paddingLeft: { xs: 3, md: '15px' } }}>
          <Typography variant="body1" sx={{ mb: 1 }}>
            Tel. 123-456-7890
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            Fern Building 827 P. Paredes St. Sampaloc Manila, NCR, Philippines
          </Typography>
        </Grid>
      </Grid>
      <Stack direction="row" justifyContent="end" alignItems="center" sx={{ mt: 3 }}>
        <Typography variant="body2" sx={{ mr: 1 }}>
          Follow Us:
        </Typography>
        <Link href="https://www.facebook.com/7-Star-Manpower-Services-of-the-Philippines-Corp-633111266817755" target="_blank" rel="noreferrer" sx={{ mr: 1 }}>
          <Icon icon={facebookFill} width="2rem" height="2rem" style={{ color: 'black' }} />
        </Link>
        <Link href="https://www.instagram.com/sparkletimekeeping?igsh=MTZkNXVoOGpmc2c4MQ%3D%3D" target="_blank" rel="noreferrer" sx={{ mr: 1 }}>
          <Icon icon="ri:instagram-fill" width="2rem" height="2rem" style={{ color: 'black' }} />
        </Link>
        <Link href="https://www.youtube.com/playlist?list=PLTiZGCFLiT09hO_i5vAS5mZtd-ixzO-on" target="_blank" rel="noreferrer" sx={{ mr: 1 }}>
          <Icon icon="mingcute:youtube-fill" width="2rem" height="2rem" style={{ color: 'black' }} />
        </Link>
        <Link href="https://www.linkedin.com/in/danica-clarisse-damilig-13128a241/?trk=universal-search-cluster" target="_blank" rel="noreferrer" sx={{ mr: 1 }}>
          <Icon icon="ri:linkedin-fill" width="2rem" height="2rem" style={{ color: 'black' }} />
        </Link>
      </Stack>
      <Box
        sx={{
          background: 'linear-gradient(90deg, rgba(16,196,219,1) 0%, rgba(8,111,216,1) 100%)',
          height: '100px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography variant="body1" sx={{ mt: 3, fontSize: 18, textAlign: 'center' }}>
          © 2024 by Sparkle Time-Keeping System
        </Typography>
      </Box>
    </RootStyle>
  );
}
