import YouTube from 'react-youtube';
// material
import { styled } from '@mui/material/styles';
import { Container, Typography, Grid, Button, Link, Box } from '@mui/material';
import { motion } from 'framer-motion';
import { varFadeInLeft, varFadeInRight } from '../animate';

const cover = '/static/background/wavy-one.png';

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  marginBottom: 64,
  paddingTop: 42,
  paddingBottom: 42,
  height: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

const TextBox = styled(Box)(({ theme }) => ({
  zIndex: 1,
  position: 'absolute',
  top:{xs:'50%', sm:'50%'},
  left: '24%',
  transform: 'translate(-50%, -50%)',
  textAlign: 'left',
  color: '#0176BC',
  [theme.breakpoints.down('md')]: {
    position: 'relative',
    top: 'auto',
    left: 'auto',
    transform: 'none',
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
}));

const ImageBox = styled(Box)(({ theme }) => ({
  width: '90%',
  height: 'auto',
  display: 'block',
  margin: '0 auto',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const YouTubePlayer = ({ videoId }) => {
  const opts = {
    height: '390',
    width: '640',
    playerVars: {
      autoplay: 1,
    },
  };

  return <YouTube videoId={videoId} opts={opts} />;
};

export default function HomeFeature() {
  return (
    <RootStyle>
      <motion.div variants={varFadeInLeft} style={{ marginTop: 0 }}>
        <TextBox>
          <Typography
            variant="h3"
            sx={{
              fontSize: { xs: '1.5rem', sm: '2.5rem', md: '3rem' },
              color: '#0176BC',
            }}
          >
            Powerful Features for Effective
          </Typography>
          <Typography
            variant="h3"
            sx={{
              fontSize: { xs: '1.5rem', sm: '2.5rem', md: '3rem' },
              color: '#0176BC',
            }}
          >
            Time Management
          </Typography>
        </TextBox>
        <ImageBox
          component="img"
          src="/static/deviceFeature.png"
        />
      </motion.div>
    </RootStyle>
  );
}
