import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

const MOCK_CAROUSELS = [
  {
    id: 1,
    image: '/static/syzygy.png'
  },
  {
    id: 2,
    image: '/static/sevenstar.jpg'
  },
  {
    id: 3,
    image: '/static/sevenstarJasem.jpg'
  }
];

const RootStyle = styled('div')(() => ({
  overflow: 'hidden',
  position: 'relative',
  marginTop: 100,
  marginBottom: 50,
  height: 'auto'
}));

const CarouselImgStyle = styled('img')(({ theme }) => ({
  width: '100%',
  height: 'auto',
  margin: 'auto',
  objectFit: 'contain',
  transition: theme.transitions.create('all'),
  maxWidth: 500,
  [theme.breakpoints.down('sm')]: {
    maxWidth: 250
  }
}));

// ----------------------------------------------------------------------

CarouselItem.propTypes = {
  item: PropTypes.object
};

function CarouselItem({ item }) {
  const { image, title } = item;

  return (
    <Box
      sx={{
        mx: 1,
        borderRadius: 2,
        overflow: 'hidden',
        position: 'relative',
        width: '100%',
        maxWidth: { xs: 250, md: 500 }
      }}
    >
      <CarouselImgStyle alt={title} src={image} />
    </Box>
  );
}

export default function HomeCompany() {

  return (
    <RootStyle>
      <Box sx={{ textAlign: 'center', mb: 10 }}>
        <Typography variant='h4' sx={{ fontSize: { xs: '16px', md: '38px' } }}> Become part of the Sparkle Time Keeping app </Typography>
        <Typography variant='h4' sx={{ fontSize: { xs: '12px', md: '28px' } }}> community, which boasts over 3,000 users. </Typography>
        <Typography sx={{ mt: 2, fontSize: { xs: '10px', md: '24px' } }}> We believe every client is a valuable long-term partner. </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
        {MOCK_CAROUSELS.map((item, index) => (
          <CarouselItem key={index} item={item} />
        ))}
      </Box>
    </RootStyle>
  );
}
